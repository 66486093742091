import { Container, Stack, Icon, Box, Image, Text, Flex, VStack, HStack, Spacer, Badge, Tag, Link, Button, Input, Show, Divider, Center } from '@chakra-ui/react'
import { BsArrowRightShort, BsStars, BsTruck } from "react-icons/bs";
import { FiAward, FiBell, FiExternalLink, FiTruck } from 'react-icons/fi';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { FaArrowDown, FaArrowRight, FaCheckCircle, FaCoins, FaEllipsisV, FaFire, FaInfo, FaRegCalendarAlt, FaRoute, FaShippingFast, FaSitemap, FaSnowflake, FaStore, FaTruck, FaTruckLoading, FaUsers, FaViber } from 'react-icons/fa';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import CountrySelect from '../components/Form/CountrySelect';
import { useForm } from 'react-hook-form';
import { TbGlass } from 'react-icons/tb';
import { RiShieldFlashFill } from 'react-icons/ri';

import { displayDate } from '~/helpers/dateHelper';
import { TRequestLoadingType } from '../resources/interfaces';
import { Trans, useTranslation } from 'react-i18next';


export default function Landing() {
  const { register, handleSubmit, control, getValues } = useForm();

  const addresses = [
    { country: 'ba', city: 'Sarajevo', address: 'Džemala Bijedića 280' },
    { country: 'de', city: 'Bernau', address: 'Jahnstrasse 74' },
    { country: 'ba', city: 'Tuzla', address: 'Ive Andrića 10' },
    { country: 'de', city: 'München', address: 'Rhinstrasse 82' },
    { country: 'de', city: 'Weilheim', address: 'Bleibtreustraße 60' },
    { country: 'ba', city: 'Bihać', address: 'Cazinska 11' },
    { country: 'ba', city: 'Bihać', address: 'Cazinska 11' },
    { country: 'hr', city: 'Zagreb', address: 'Aleja Lipa 54' },
    { country: 'rs', city: 'Beograd', address: 'Plavska 19' }
  ];

  return (

    <>
      <Box py={3} bg={'gray.600'} color={'white'}>
        <Container maxW={'container.xl'}>
          <Stack direction={['column', 'row', 'row']} spacing={10}>
            <a href="https://cargo.ba/obuka-za-transportne-speditere/" target="_blank">
              <Image src={'http://cargo.ba/wp-content/uploads/2017/08/1-3.png'} p={1} borderRadius={'md'} bg={'white'} />
            </a>
            <VStack alignItems={'left'}>
              <Text fontWeight={'bold'}>Prijavite se na Obuku za transportne špeditere - Cargo.ba.</Text>
              <Text>
                Obuka za transportne špeditere pruža vam priliku da postanete stručan transportni špediter i unaprijedite svoju karijeru u logističkoj industriji.
              </Text>
            </VStack>
            <Box>
              <Button as={Link} target="_blank" href={'https://cargo.ba/obuka-za-transportne-speditere/'} colorScheme={'orange'} rightIcon={<Icon as={FiExternalLink} />}>Prijava na obuku</Button>

            </Box>

          </Stack>

        </Container>
      </Box>

      <Box zIndex={1000} bgSize={'cover'} pt={5} bgImage={['', '/images/bg_2.png']} bgRepeat={'no-repeat'} bgPosition={'right'} boxShadow={'lg'} >
        <Container maxW={'container.xl'}>
          <Stack alignItems={'center'} direction={['column', 'row']} gap={[3, 10]} justifyContent={'space-between'}>
            <Box justifyContent={'flex-start'} w={'full'}>
              <Image mx={['auto', '0']} maxW={240} src={'/images/logo.png'} alt={''} />
            </Box>
            <Box w={'full'}>
              <Stack spacing={10} justifyContent={'center'} direction={'row'}>
                <Link href={'/#'}>info@etransport.ba</Link>
              </Stack>
            </Box>
            <Box w={'full'} textAlign={'right'}>
              <HStack justifyContent={['center', 'flex-end']}>
                <Link fontSize={'md'} as={ReachLink} to={'/register'} mr={5}>Registracija</Link>
                <Button size={'md'} as={ReachLink} to={'/login'} colorScheme={'blue'} variant={'solid'} >
                  Prijava
                  <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
                </Button>
              </HStack>

            </Box>
          </Stack>
        </Container>

        <Spacer h={['50px', '50px']} />

        <Box p={3} w={'full'}>
          <Container maxW={'container.xl'} p={0}>
            <VStack maxW={['center', 'container.md']} alignItems={['center', 'flex-start']} spacing={6}>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'blue.500'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>

                    <Icon as={FaStore} fontSize={'48px'} />
                    <Box>
                      <Text>Proizvodne i trgovinske firme</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>Trebate transport robe?</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    Otvorite zahtjev za transport
                  </Button>
                </Stack>
              </Box>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'orange'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>
                    <Icon as={FaTruck} fontSize={'48px'} />
                    <Box>
                      <Text>Prijevoznici</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>Imate slobodno vozilo?</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    Pregledajte ponude robe i tereta
                  </Button>
                </Stack>
              </Box>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'teal'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>
                    <Icon as={FaUsers} fontSize={'48px'} />
                    <Box>
                      <Text>Špediteri</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>Želite proširiti poslovanje?</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    Registrujte se
                  </Button>
                </Stack>
              </Box>
            </VStack>
          </Container>
        </Box >
        <Spacer h={['50px', '100px']} />
      </Box>

      <Spacer h={'50px'} />

      <Container maxW={'container.xl'} id={'transport'} p={5}>
        <Text textAlign={'center'} fontSize={'xl'} fontWeight={'bold'}>Dobrodošli na našu platformu za berzu transporta - mjesto gdje Vaša firma može zaraditi više novca nego ikada prije!</Text>

        <Text mt={5}>Mi razumijemo koliko je važno za firme da ostvare profit i održe stabilnost u današnjem poslovnom okruženju. Zato smo pokrenuli inovativnu berzu transporta koja vam pomaže da maksimalno iskoristite svoje kapacitete i ostvarite veći prihod.</Text>

        <Text mt={5}>Naša platforma funkcioniše na jednostavan način. Preduzeća koja posjeduju slobodne kapacitete u svom transportnom voznom parku mogu se registrovati i ponuditi svoje usluge na tržištu kao i poslati ponude transporta robe ili tereta sa cijenom. Istovremeno, druga preduzeća koja imaju potrebu za transportom robe mogu zatražiti ponudu za transport robe ili tereta od strane prijevozničkih preduzeća i pronaći najbolju ponudu za svoje potrebe.</Text>

        <Spacer h={'50px'} />

        <Center mb={10}>
          <Button target={'_blank'} as={Link} href={'https://invite.viber.com/?g2=AQAGj3Gw9SVE3lDcjuo41C31mjuYlX8B%2BdZpDxh0FqPikaxvM0a8eptnBoYulTA%2B'} leftIcon={<Icon as={FaViber} />} colorScheme={'purple'}>Pridružite se Viber kanalu</Button>
        </Center>

      </Container>

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Pronađite prijevoznika u nekoliko minuta.</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                Uštedite Vaše vrijeme i trud koristeći našu naprednu platformu za berzu transporta koja vam omogućuje da u samo nekoliko minuta pronađete idealnog prijevoznika za Vašu robu ili teret, oslobađajući Vas od dugotrajnog pretraživanja i pregovaranja.
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                Registrujte se kao proizvodno ili trgovinsko preduzeće
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <VStack>

                  <Image borderRadius={'lg'} boxShadow={'base'} src={'/images/europe.jpg'} />
                  <Text fontSize={'xs'} color={'gray'}>Pronađite teret ili robu na teritoriji Europe.</Text>

                </VStack>
              </Center>
            </Box>
          </Stack>
        </Box>

      </Container>

      <Spacer h={['0px', '100px']} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Jednostavan pregled za <br /> prijevoznička preduzeća.</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                Na našoj platformi možete pratiti zahtjeve za transport robe ili tereta i poslati Vašu ponudu u nekoliko klikova. Budite u korak sa zahtjevima uz obavještenja na Viber ili e-mail.
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                Registrujte se kao prijevozničko preduzeće
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'left'}>
              <Show above={'sm'}>
                <TableContainer borderRadius={'md'} boxShadow={'base'}>
                  <Table boxShadow={'base'} variant={'primary'}>
                    <Thead>
                      <Tr>
                        <Th>Datum</Th>
                        <Th>Preuzimanje</Th>
                        <Th>Dostava</Th>
                        <Th textAlign={'center'}>Info</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {[1, 2, 3, 4, 5, 6].map((i) => {
                        const loading = addresses[Math.floor(Math.random() * addresses.length)];
                        const unloading = addresses[Math.floor(Math.random() * addresses.length)];

                        return (
                          <Tr fontSize={'sm'}>
                            <Td>20.02.2024</Td>
                            <Td>
                              <HStack spacing={5}>
                                <CircleFlag countryCode={loading.country} width={'16px'} />
                                <VStack alignItems={'left'} spacing={0}>
                                  <Text>{loading.city}</Text>
                                  <Text color={'gray'} fontSize={'xs'}>{loading.address}</Text>
                                </VStack>
                              </HStack>
                            </Td>
                            <Td>
                              <HStack spacing={5}>
                                <CircleFlag countryCode={unloading.country} width={'16px'} />
                                <VStack alignItems={'left'} spacing={0}>
                                  <Text>{unloading.city}</Text>
                                  <Text color={'gray'} fontSize={'xs'}>{unloading.address}</Text>
                                </VStack>
                              </HStack>
                            </Td>
                            <Td textAlign={'center'}>
                              <Button as={ReachLink} to={'/register'} colorScheme={'blue'} size={'xs'} leftIcon={<FiTruck />}>
                                Pošalji ponudu
                              </Button>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Show>
              <Show below='md'>
                <Text mb={5} textAlign={'center'} fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Jednostavan pregled za <br /> prijevoznike i špeditere.</Text>

                <Box mb={5} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} py={5} px={6} borderRadius={'md'} boxShadow={'md'}>
                  <HStack alignItems={'flex-start'}>
                    <HStack w={'full'} spacing={6}>
                      <VStack spacing={4}>
                        <CircleFlag countryCode={'ba'} width={'16px'} />
                        <Icon as={FaEllipsisV} />
                        <CircleFlag countryCode={'de'} width={'16px'} />
                      </VStack>
                      <VStack alignItems={'left'}>
                        <HStack spacing={5}>
                          <VStack spacing={0} alignItems={'left'}>
                            <Text>Sarajevo</Text>
                            <Text color={'gray'} fontSize={'xs'}>Džemala Bijedića 100</Text>
                          </VStack>
                        </HStack>
                        <Spacer h={'20px'} />
                        <HStack spacing={5}>
                          <VStack spacing={0} alignItems={'left'}>
                            <Text>Berlin</Text>
                            <Text color={'gray'} fontSize={'xs'}>Bernauer Straße 10</Text>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                    <Box>
                      <HStack justifyContent={'flex-end'} mb={2}>
                        <Icon as={FaFire} color={'red.500'} />
                        <Icon as={FaSnowflake} color={'blue.500'} />
                        <Icon as={FaTruckLoading} color={'green'} />
                        <Icon as={TbGlass} color={'orange.700'} />
                      </HStack>
                      <VStack alignItems={'flex-end'}>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'green'}>Zbirni tr.</Tag>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>4500 kg</Tag>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>5 paleta</Tag>
                      </VStack>
                    </Box>
                  </HStack>

                  <Divider my={4} />

                  <HStack justifyContent={'space-between'}>
                    <HStack spacing={5}>
                      <Icon as={FaRegCalendarAlt} />
                      <VStack alignItems={'left'} spacing={0}>
                        <Text fontSize={'sm'}>16.04.2023</Text>
                        <Text color={'gray'} fontSize={'xs'}>Datum utovara</Text>
                      </VStack>
                    </HStack>
                    <VStack alignItems={'flex-end'} textAlign={'right'}>
                      <Image alt={'šleper'} cursor={'pointer'} src={'/images/vehicles/semitruck-hard-cover.webp'} mixBlendMode={'multiply'} w={'48px'} />

                      <Text textAlign={'right'} color={'gray'} fontSize={'xs'}>Mega Šleper</Text>
                    </VStack>
                  </HStack>

                  <Divider my={4} />

                  <HStack justifyContent={'space-between'}>
                    <HStack spacing={5}>


                      <Text color={'gray'} fontSize={'xs'} p={0} mb={2} textAlign={'center'}>
                        Objavljeno prije 2 sata.
                      </Text>
                    </HStack>
                    <Box textAlign={'right'} mt={5}>
                      <Button as={ReachLink} to={`/register`} size={'sm'} colorScheme={'blue'}>Pregledaj</Button>
                    </Box>
                  </HStack>
                </Box>
              </Show>
            </Box>
          </Stack>
        </Box>

      </Container>


      <Spacer h={'100px'} />

      <Box bg={'whitesmoke'} boxShadow={'lg'}>
        <Container maxW={'container.xl'} py={10}>
          <Box p={5} w={'full'}>
            <Stack spacing={10} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
              <Show below={'md'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Za proizvodna i trgovinska <br />preduzeća, efikasno.</Text>
              </Show>

              <Box py={10} px={5} bg={'white'} boxShadow={'md'} borderRadius={'lg'} w={'full'} textAlign={'left'}>
                <VStack spacing={0} justifyContent={'space-between'} >
                  <Box>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Preuzimanje</Text>
                    <CountrySelect defaultValue={'BA'} control={control} name={'loading_country'} />
                    <Spacer h={'10px'} />
                    <HStack>
                      <Input defaultValue={'Sarajevo'} placeholder={'Grad'}></Input>
                      <Input defaultValue={'Džemala Bijedića 100'} placeholder={'Adresa'}></Input>
                    </HStack>
                  </Box>

                  <Box pt={5}>
                    <Icon fontSize={'20px'} as={FaArrowDown} mb={'5px'} />
                  </Box>

                  <Box>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dostava</Text>

                    <CountrySelect control={control} defaultValue={'DE'} name={'unloading_country'} />
                    <Spacer h={'10px'} />
                    <HStack>
                      <Input defaultValue={'Berlin'} placeholder={'Grad'}></Input>
                      <Input defaultValue={'Bernauer Straße 10'} placeholder={'Adresa'}></Input>
                    </HStack>
                  </Box>
                  <Box>
                    <Button as={ReachLink} to={'/register'} mt={10} leftIcon={<FaCheckCircle />} colorScheme={'green'}>Napravi zahtjev</Button>
                  </Box>
                </VStack>
              </Box>
              <Box w={'full'} textAlign={['center', 'left']}>
                <Show above={'sm'}>
                  <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Za proizvodna i trgovinska <br />preduzeća, efikasno.</Text>
                </Show>

                <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                  Uz nekoliko klikova otvorite zahtjev za transport robe ili tereta na našoj platformi i pratite ponude prijevozničkih/špediterskih preduzeća. Odaberite optimalnu ponudu i zatvorite zahtjev. Tako jednostavno.
                  <br />
                </Text>
                <Button mb={5} as={ReachLink} to={'/register'} whiteSpace={'break-spaces'} mt={10} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                  Registrujte se kao proizvodno preduzeće
                  <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
                </Button>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>


      <Spacer h={'100px'} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Učinkovito <br /> za špeditere.</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                Ova inovativna berza transporta namijenjena je i špediterima kako bi jednostavno objavili ili pronašli ponudu robe i tereta. Naša ciljana platforma omogućuje Vam da učinkovito povežete svoje potrebe s pouzdanim prijevoznicima i pronađete optimalna rješenja za svoje poslovne zahtjeve.
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                Registrujte se kao špediter
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <Image maxW={'275px'} src={'/images/phone.png'} />
              </Center>
            </Box>
          </Stack>
        </Box>
      </Container>

      <Spacer h={'100px'} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>Naši partneri.</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                Ukoliko ste zainteresovani za saradnju sa nama, slobodno nas kontaktirajte. Naš tim će Vam pružiti sve informacije o mogućnostima saradnje i prednostima koje nudimo našim partnerima.
                <br />
              </Text>
              <Button as={Link} to={'mailto:info@etransport.ba'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                info@etransport.ba
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <VStack spacing={5}>
                  <a href="http://cargo.ba" target="_blank">
                    <Image src={'http://cargo.ba/wp-content/uploads/2017/08/1-3.png'} />
                  </a>
                  <Text fontSize={'xs'}>Cargo.ba je logistički adresar Bosne i Hercegovine.</Text>
                </VStack>
              </Center>
            </Box>
          </Stack>
        </Box>
      </Container>

      <Spacer h={'50px'} />

      <Container maxW={'container.md'}>

        <Spacer h={'50px'} />

        <HStack justifyContent={'space-between'}>
          <HStack>
            <CircleFlag countryCode={'eu'} width={'36px'} />

            <Text fontSize={'xs'}>
              © eTransport.ba 2024.
            </Text>
          </HStack>

          <Box fontSize={'xs'}>
            <Link href={'/privacy'}>Politika privatnosti</Link>
            <Link href={'/terms'} ml={5}>Uslovi korištenja</Link>
          </Box>
        </HStack>
      </Container>

      <Spacer h={'50px'} />
    </>
  )
}
